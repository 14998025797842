<template>
  <v-container>
    <h1>FAQ</h1>

    <h2>What is Pop Mystic?</h2>
    Pop Mystic is an index of spoken utterances in movies and television shows.
    These utterances are the words that would appear if you watched a movie with
    subtitles, or watched television with closed captioning turned on.

    <h2>How do I use Pop Mystic?</h2>
    Pop Mystic enables researchers to locate specific words or phrases within
    the lines of dialog of thousands of movies and television shows. Type a word
    or phrase in the search box and hit return to get instance where that word
    or phrase has been uttered, or dial it in a little more by specifying the
    genre, release year, title, director, etc. that you want to confine the
    search to.
    <h2>How do I include information from Pop Mystic in my research?</h2>
    Once Pop Mystic has located the target utterance, a researcher knows in
    which movie or show the phrase appears, and at what point in its run-time it
    occurs. It’s important to remember that in order to use a quote from a movie
    or tv show in an academically rigorous way, the primary source must be
    consulted. In the same way one doesn’t cite Wikipedia as a source in one’s
    bibliography, one shouldn’t cite Pop Mystic. If you are using a quote in a
    school paper or professional research, you must view that segment of the
    movie or show in its original form in order to know what the words actually
    represent, what’s happening on-screen, etc.
    <h2>How do I download the whole script to a movie or show?</h2>
    Pop Mystic doesn’t provide full scripts, only the snippet view of the target
    utterance and the few lines before and after it in order to give context.
    <h2>Where does this data come from?</h2>
    Pop Mystic’s data is crowd-sourced and collected from all over the globe.
    <h2>Is this data reliable?</h2>
    Generally the quality of the data is excellent, but it isn’t perfect. You
    may come across misspelled words for example. Take common misspellings into
    account when writing your search terms. Try searching for “their going to
    take there hats off” and other misspelled variations if you don’t get the
    result you want when spelling the words in the target phrase correctly.
    <h2>Can I search movies that are in languages other than English?</h2>
    At the moment the Pop Mystic database contains utterances only from the
    English language transcripts of movies and television shows. This means that
    though the original language of a movie might be Korean, the only data in
    the Pop Mystic database for that movie is from its English translation. In
    the not-to-distant future we hope to augment our database with other
    language data.
  </v-container>
</template>

<script>
export default {
  name: "FAQ",
};
</script>
